import React, { useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import useBookingDates from "@/hooks/useBookingDates";

type OnSelectFunction = (lat: number, lng: number, place: string) => void;

interface PlacesAutocompleteProps {
  onSelect: OnSelectFunction;
  placerholder?: string;
}

const PlacesAutocomplete = ({
  onSelect,
  placerholder,
}: PlacesAutocompleteProps) => {
  const { place } = useBookingDates();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  useEffect(() => {
    setValue(place, false); // Establecer el valor inicial de `value` como `place`
  }, [place, setValue]);

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  };

  const handleSelect = (description: string) => () => {
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      onSelect(lat, lng, description);
    });
  };

  const renderSuggestions = () => {
    return data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className="py-1 w-auto hover:bg-violet-100 px-4 hover:text-violet-700 truncate text-ellipsis"
          key={place_id}
          onClick={handleSelect(suggestion.description)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  };

  return (
    <div ref={ref} className="w-full flex-col relative">
      <input
        className="flex-grow w-full bg-transparent outline-none text-sm text-gray-600 placeholder-gray-400"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={
          placerholder ? placerholder : "Ciudad, aeropuerto, dirección"
        }
      />
      {status === "OK" && (
        <ul className="absolute mt-4 bg-white rounded-lg shadow-lg z-50 w-full">
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
