import React, { ReactNode, useState } from "react";

interface Props {
  children?: ReactNode;
  onToggle?: (isChecked: boolean) => void;
}

function CheckInput({ children, onToggle }: Props) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = event.target.checked;
    setIsChecked(newCheckedValue);

    // Llama a la función de control pasada desde el padre, si está definida
    if (onToggle) {
      onToggle(newCheckedValue);
    }
  };

  return (
    <div className="flex items-center gap-x-2">
      <input
        id="default-checkbox"
        type="checkbox"
        value=""
        className="accent-violet-700 focus:accent-violet-700 ring-offset-neutral-300 focus:ring-offset-violet-700"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label className="text-neutral-500 text-center font-light text-sm">
        {children}
      </label>
    </div>
  );
}

export default CheckInput;
