import Image from "next/image";
import React from "react";

interface Props {
  image: string;
}

function GuideImage({ image }: Props) {
  return (
    <div className="relative min-w-[250px] my-2 mx-5 justify-center">
      <Image
        src={`/assets/images/${image}`}
        alt={image}
        width={300}
        height={300}
        blurDataURL={`/assets/images/${image}`}
        placeholder="blur"
        loading="lazy"
        unoptimized
        className="w-full h-auto object-cover"
      />
    </div>
  );
}

export default GuideImage;
