import { BanknotesIcon } from "@heroicons/react/24/solid";
import React from "react";

interface Props {
  id: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  type?: string;
  disabled?: boolean;
  formatPrice?: boolean;
  required?: boolean;
  errors?: string | null;
}

const Input: React.FC<Props> = ({
  id,
  value,
  onChange,
  label,
  type = "text",
  disabled = false,
  formatPrice = false,
  errors,
  required = false,
}) => {
  return (
    <div className="relative w-full">
      {formatPrice && (
        <BanknotesIcon className="absolute top-5 left-2 h-3 text-neutral-700" />
      )}
      <input
        id={id}
        value={value}
        type={type}
        onChange={onChange}
        disabled={disabled}
        required={required}
        placeholder=" "
        className={`peer w-full p-1 pt-5 bg-white border-2 rounded-md font-light outline-none transition
          ${formatPrice ? "pl-8" : "pl-3"}
          ${
            errors
              ? "border-red-500 focus:border-red-500"
              : "border-neutral-300 focus:border-violet-700"
          }
          ${disabled ? "opacity-70 cursor-not-allowed" : ""}
        `}
      />
      <label
        htmlFor={id}
        className={`absolute text-md text-sm duration-150 transform -translate-y-3 top-4 z-10 origin-[0]
          ${formatPrice ? "left-9" : "left-4"}
          peer-placeholder-shown:scale-95 peer-placeholder-shown:translate-y-0 peer-focus:scale-65 peer-focus:-translate-y-4
          ${errors ? "text-red-500" : "text-zinc-400"}
        `}
      >
        {label}
      </label>
      {errors && <p className="mt-1 text-xs text-red-500">{errors}</p>}
    </div>
  );
};

export default Input;
