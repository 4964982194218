/**
 * createConnection Function
 * @param {string} url - The relative URL of the API endpoint to connect to.
 * @param {string} method - The HTTP method for the API request (e.g., GET, POST, PUT, DELETE, etc.).
 * @param {Object} [headers] - Optional HTTP headers for the API request.
 * @param {Object} [body] - Optional JSON data to be included in the request body.
 * @param {FormData} [data] - Optional FormData object for sending file uploads or binary data.
 * @param {Object} [params] - Optional URL parameters for the API request.
 * @returns {Promise<Object>} - A promise that resolves to the response data received from the API server.
 */

import axios, { AxiosRequestConfig } from "axios";

export async function createConnection({
  url,
  method,
  headers,
  body,
  data,
  params,
}: {
  url: string;
  method: string;
  headers?: any;
  body?: object;
  data?: FormData;
  params?: any;
}) {
  // Create an AxiosRequestConfig object with the provided options.
  const axiosConfig: AxiosRequestConfig = {
    method,
    url: `${process.env.NEXT_PUBLIC_SERVER_URL_PROD}${url}`,
    headers: {
      ...headers,
      "Content-Type": "application/json",
      "x-api-key": process.env.NEXT_PUBLIC_X_API_KEY ?? "",
    },
    data: data ? data : body,
    params: params,
  };
  // If FormData is used for file uploads, remove the "Content-Type" header as it's handled automatically by Axios.
  if (data && axiosConfig.headers) {
    delete axiosConfig.headers["Content-Type"];
  }
  // Send the API request using Axios and wait for the response.
  const response = await axios(axiosConfig);
  // Return the data received from the API response.
  return response.data;
}
