"use client";
import { useRouter } from "next/navigation";

// Functions to handle different link clicks
// Each function uses the router instance to navigate to a specific route or open a URL in a new tab

const LinksNavigation = () => {
  const router = useRouter();

  const handleClickTerms = () => {
    router.push("/terms");
  };

  const handleClickForgotPassword = () => {
    router.push("/forgotPassword");
  };

  const handleClickPolicies = () => {
    router.push("/policies");
  };

  const handleClickLogin = () => {
    router.push("/login");
  };

  const handleClickRegister = () => {
    router.push("/register");
  };

  const handleClickBecomeHost = () => {
    router.push("/becomeHost");
  };

  const handleClickHowWork = () => {
    router.push("/guide");
  };

  const handleClickHowInvest = () => {
    router.push("/howInvest");
  };

  const handleClickWhyRent = () => {
    router.push("/whyRent");
  };

  const handleClickCabify = () => {
    router.push("/cabify");
  };

  const handleClickInsurance = () => {
    router.push("/insurance");
  };

  const handleClickPhoneLink = () => {
    window.open("tel:+5402944657347");
  };

  const handleClickGoogleMaps = () => {
    window.open("https://goo.gl/maps/VbqjrobHZUQvQwwf9", "_blank");
  };

  const handleClickFacebook = () => {
    window.open(
      "https://www.facebook.com/ATUA-Car-Rental-109371977298631",
      "_blank"
    );
  };

  const handleClickInstagram = () => {
    window.open("https://www.instagram.com/atuaapp/", "_blank");
  };

  const handleClickWhatsappSoporte = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5491144459333&text=Hola%20equipo%20de%20Atua.%20Necesito%20su%20ayuda.",
      "_blank"
    );
  };

  const handleClickWhatsappBooking = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5491131444404&text=Hola%20Atua%2C%20queria%20averiguar%20para%20alquilar%20un%20auto%20con%20ustedes.%0AProvincia%3A%0AFecha%20de%20entrega%3A%0AFecha%20de%20devoluci%C3%B3n%3A%0AGracias!",
      "_blank"
    );
  };

  const handleClickAppStore = () => {
    window.open("https://apps.apple.com/ec/app/atua/id1643579766", "_blank");
  };

  const handleClickPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.globalizarg.atua_app",
      "_blank"
    );
  };

  const handleClickOwnerPosts = () => {
    router.push("/user/posts");
  };

  const handleClickOwnerCars = () => {
    router.push("/user/cars");
  };

  const handleClickBooking = () => {
    router.push("/user/trips");
  };

  const handleClickWallet = () => {
    router.push("/user/wallet");
  };

  const handleClickProfile = () => {
    router.push("/user/profile");
  };

  const handleClickMessage = () => {
    router.push("/user/messages");
  };

  const handleClickHome = () => {
    router.push("/");
  };

  // Return an object containing all the link click handler functions
  return {
    handleClickForgotPassword,
    handleClickHome,
    handleClickTerms,
    handleClickPolicies,
    handleClickLogin,
    handleClickRegister,
    handleClickBecomeHost,
    handleClickHowWork,
    handleClickHowInvest,
    handleClickWhyRent,
    handleClickCabify,
    handleClickInsurance,
    handleClickPhoneLink,
    handleClickGoogleMaps,
    handleClickFacebook,
    handleClickInstagram,
    handleClickWhatsappSoporte,
    handleClickWhatsappBooking,
    handleClickAppStore,
    handleClickPlayStore,
    handleClickOwnerPosts,
    handleClickOwnerCars,
    handleClickBooking,
    handleClickWallet,
    handleClickProfile,
    handleClickMessage,
  };
};

export default LinksNavigation;
