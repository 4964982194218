import { LOCATIONS } from "@/constants/data";
import { addDays } from "date-fns";
import { create } from "zustand";

interface State {
  location: string;
  place: string;
  startDate: Date;
  startHour: Date;
  endDate: Date;
  endHour: Date;
  setLocation: (location: string) => void;
  setPlace: (location: string) => void;
  setStartDate: (startDate: Date) => void;
  setStartHour: (startHour: Date) => void;
  setEndDate: (endDate: Date) => void;
  setEndHour: (endHour: Date) => void;
}

const useBookingDates = create<State>((set) => ({
  location: LOCATIONS.BUENOS_AIRES,
  place: "",
  startDate: new Date(),
  startHour: new Date(),
  endDate: addDays(new Date(), 3),
  endHour: new Date(),
  setLocation: (string) => set({ location: string }),
  setPlace: (string) => set({ place: string }),
  setStartDate: (date) => set({ startDate: date }),
  setStartHour: (date) => set({ startHour: date }),
  setEndDate: (date) => set({ endDate: date }),
  setEndHour: (date) => set({ endHour: date }),
}));

export default useBookingDates;
