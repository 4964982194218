/**
 * Capitalizes the first letter of a string.
 * @param {string} str - The input string.
 * @returns {string} - The string with the first letter capitalized.
 */

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function removeSubstringFromString(
  fullString: string,
  substringToRemove: string
): string {
  return fullString.replace(substringToRemove, "");
}

export function formatNumberDecimal(texto: string): string {
  const partes = [];
  let contador = 0;
  let parteActual = "";

  for (let i = texto.length - 1; i >= 0; i--) {
    parteActual = texto[i] + parteActual;
    contador++;

    if (contador === 3) {
      partes.unshift(parteActual);
      parteActual = "";
      contador = 0;
    }
  }

  if (parteActual !== "") {
    partes.unshift(parteActual);
  }

  return partes.join(".");
}

export function formatCurrency(value: string, addition?: number): string {
  // Remover el símbolo de dólar y convertir el string a número
  const numericValue = parseFloat(value.replace("$", ""));

  if (isNaN(numericValue)) {
    throw new Error(
      "Invalid input: the value must be a string in the format '$123456'."
    );
  }

  // Si se proporciona un número adicional, se suma al valor original
  const totalValue = numericValue + (addition || 0);

  // Formato para miles con separador de miles
  return `$${totalValue.toLocaleString("es-CO")}`;
}

export function formatPhone(phone: string): string {
  const cleaned = phone.replace(/\D/g, '');

  if (cleaned.length !== 13 || !cleaned.startsWith('549')) {
    return phone; 
  }

  // Aplica el formato deseado
  const countryCode = cleaned.slice(0, 3);  // +54
  const regionalCode = cleaned.slice(3, 4); // 9
  const areaCode = cleaned.slice(4, 6);     // 11
  const firstPart = cleaned.slice(6, 10);   // 3144
  const secondPart = cleaned.slice(10);     // 4404

  return `+${countryCode} ${regionalCode} ${areaCode} ${firstPart}-${secondPart}`;
}